import type { AxiosInstance } from 'axios';

interface Item {
  id: string;
  name: string;
}
interface ReportCompanies {
  skip: number;
  limit: number;
  total: number;
  items: Item[];
}

interface PowerBIOptions {
  id: string;
  name: string;
  web_url: string;
  embed_url: string;
}

interface PowerBIResponse {
  items: PowerBIOptions[];
}

interface PowerBITokenResponse {
  embed_token: string;
}

export const buildReportsApi = (httpClient: AxiosInstance) => {
  const getCompanyList = async (
    search?: string,
    skip?: number,
    limit?: number,
    order_by?: string,
    order_direction?: string
  ): Promise<ReportCompanies> => {
    const payload: any = {
      skip: skip ? skip : 0,
      limit: limit ? limit : 1000,
      order_by: order_by ? order_by : 'name',
      order_direction: order_direction ? order_direction : 'asc',
      search: search ? search : ''
    };
    const response = await httpClient.get<ReportCompanies>(
      `/api/reporting/companies?skip=${payload.skip}&limit=${payload.limit}&order_by=${payload.order_by}&order_direction=${payload.order_direction}&search=${payload.search}`,
      payload
    );
    return response.data;
  };

  const getSiteList = async (
    companyId: string,
    search?: string,
    skip?: number,
    limit?: number,
    order_by?: string,
    order_direction?: string
  ): Promise<ReportCompanies> => {
    const payload: any = {
      skip: skip ? skip : 0,
      limit: limit ? limit : 1000,
      order_by: order_by ? order_by : 'name',
      order_direction: order_direction ? order_direction : 'asc',
      search: search ? search : ''
    };
    const response = await httpClient.get<ReportCompanies>(
      `/api/reporting/companies/${companyId}/sites?skip=${payload.skip}&limit=${payload.limit}&order_by=${payload.order_by}&order_direction=${payload.order_direction}&search=${payload.search}`
    );
    return response.data;
  };

  const getReportsOption = async (): Promise<PowerBIResponse> => {
    const response = await httpClient.get<PowerBIResponse>('/api/reporting/reports');
    return response.data;
  };

  const getReportToken = async (reportId: string): Promise<PowerBITokenResponse> => {
    const response = await httpClient.get<PowerBITokenResponse>(
      `/api/reporting/reports/${reportId}/generate-embedding-token`
    );
    return response.data;
  };

  return Object.freeze({
    getCompanyList,
    getSiteList,
    getReportsOption,
    getReportToken
  });
};

export type { ReportCompanies };
