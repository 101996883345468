import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EmptyReport from '../../components/EmptyReport';
import { DeviceForm, DeviceFormFields } from '../../components/GenerateReportForm';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import PowerBIReport from '../../components/PowerBIReport';

export const AllReportsPage: React.FC = () => {
  const [filters, setFilters] = useState<DeviceFormFields>();
  return (
    <Box maxWidth="1600px" mx="auto">
      <Box display="flex" flexDirection={'row'} justifyContent="space-between">
        <Typography variant="h4" sx={{ fontWeight: 600 }} fontSize="34px" lineHeight="42px">
          Reports
        </Typography>
        <Button fullWidth disabled={!filters?.type?.id} variant="outlined" sx={{ maxWidth: '200px', width: '100%' }}>
          <DownloadIcon sx={{ paddingRight: '8px' }} />
          Export Report
        </Button>
      </Box>
      <Box sx={{ paddingTop: '24px' }}>
        <Box>
          <DeviceForm onFilterChange={setFilters} />
        </Box>
        {!filters?.site ? <EmptyReport /> : <PowerBIReport filters={filters} />}
      </Box>
    </Box>
  );
};

export default AllReportsPage;
